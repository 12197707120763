import { addEventListeners } from '../functions/dom'
import { disableSaveBtn } from '../functions/form'
import { jsonFetch } from '../functions/http'

export class ConfirmModal extends HTMLElement {
  connectedCallback () {
    this.wrapper = this.querySelector('.confirm-modal')
    this.attachEventHandlers()

    addEventListeners(
      document.querySelectorAll('.targetConfirmModal'),
      'click',
      this.targetConfirmModal.bind(this)
    )
  }

  attachEventHandlers () {
    document
      .querySelectorAll('.js-modal-close')
      .forEach(element =>
        element.addEventListener('click', this.click.bind(this))
      )
  }

  static get observedAttributes () {
    return ['visible', 'action', 'item', 'message']
  }

  attributeChangedCallback (name, oldValue, newValue) {
    if (name === 'visible' && oldValue !== newValue) {
      this.setVisible(newValue)
    } else if (
      name === 'action' &&
      oldValue !== newValue &&
      newValue !== null
    ) {
      if (this.form) {
        this.form.action = newValue
      }
    } else if (name === 'item' && oldValue !== newValue && newValue !== null) {
      this.item = newValue
      if (this.form != null)
        this.form.addEventListener('submit', this.handleSubmit.bind(this))
    } else if (name === 'message' && oldValue !== newValue) {
      if (this.querySelector('#modalMessage')) {
        this.querySelector('#modalMessage').innerHTML = newValue
      }
    }
  }

  setVisible (value) {
    if (value) {
      this.wrapper.classList.remove('hidden')
      document.querySelector('body').classList.add('body_modal--open')
    } else {
      this.wrapper.classList.add('hidden')
      document.querySelector('body').classList.remove('body_modal--open')
    }
  }

  handleSubmit (e){
    if (this.item) {
      e.preventDefault()
      this.dispatchEvent(
        new CustomEvent('ok', {
          detail: this.item
        })
      )
      this.removeAttribute('item')
      this.item = null
    } else {
      this.removeAttribute('action')
      this.dispatchEvent(
        new CustomEvent('ok', {
          detail: this.item
        })
      )
    }
  }

  click (e) {
    if (e.target.classList.contains('js-modal-close')) {
      this.removeAttribute('visible')
      this.removeAttribute('message')
    }
  }

  targetConfirmModal (e) {
    e.preventDefault()
    const href = e.currentTarget.getAttribute('href')
    this.entity = e.currentTarget.getAttribute('entity')
    this.message = e.currentTarget.getAttribute('message')

    this.loadModal(href).then(response => {
      if (response) {
        this.modal = response
        this.modal.setAttribute('aria-modal', true)
        this.setAttribute('action', href)
      }
      this.setAttribute('visible', 'true')
      this.form = this.querySelector('form')
      this.form.addEventListener('submit', this.handleSubmit.bind(this))
      this.form.action = this.getAttribute('action')
      document.querySelectorAll('.js-modal-close').forEach(element => {
        element.addEventListener('click', this.click.bind(this))
      })
      disableSaveBtn(false)

      this.setAttribute('message', `Voulez-vous vraiment ${this.message} ?`)
    })
  }

  async loadModal (url) {
    const data = await jsonFetch(url)
    const previous = this.querySelector('aside')
    const fragment = document
      .createRange()
      .createContextualFragment(data)
      .querySelector('aside')

    if (previous) {
      this.replaceChild(fragment, previous)
    } else {
      this.append(fragment)
    }

    this.wrapper = fragment
    return fragment
  }
}
